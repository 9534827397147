.badge {
  font-size: 12px;
  font-weight: normal;
  color: #FFFFFF; }
  .badge.badge-primary {
    background-color: #469181; }
  .badge.badge-secondary {
    background-color: #8A8A8E; }
  .badge.badge-accent {
    background-color: #FFC107; }
  .badge.badge-danger {
    background-color: #BD424B; }
  .badge.badge-outline-primary {
    border: 1px solid #469181;
    color: #469181;
    background: transparent; }
  .badge.badge-outline-secondary {
    border: 1px solid #8A8A8E;
    color: #8A8A8E;
    background: transparent; }
  .badge.badge-outline-accent {
    border: 1px solid #FFC107;
    color: #FFC107;
    background: transparent; }
  .badge.badge-outline-danger {
    border: 1px solid #BD424B;
    color: #BD424B;
    background: transparent; }

.banner {
  position: relative;
  height: 40vh;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center; }
  .banner::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.5); }
  .banner .banner-image {
    position: absolute;
    background-size: cover;
    background-position: center top;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0; }
  .banner .banner-content {
    z-index: 1; }

.btn {
  padding-left: 2rem;
  padding-right: 2rem;
  color: #469181;
  border: 2px solid #469181;
  border-radius: 0.25rem;
  transition: 0.5s;
  font-weight: bold; }
  .btn:hover {
    opacity: 0.9;
    color: #FFFFFF;
    background-color: #469181; }
  .btn.badge-pill {
    border-radius: 10rem; }
  .btn.btn-lg {
    padding: 0.75rem 4rem;
    font-size: 18px; }
  .btn.btn-primary {
    color: #FFFFFF;
    background-color: #469181 !important;
    border: none !important; }
  .btn.btn-accent {
    color: #FFFFFF;
    background-color: #FFC107;
    border: none !important; }
  .btn.btn-secondary {
    color: #FFFFFF;
    background-color: #8A8A8E !important;
    border: none !important; }

.card {
  border: none;
  background-color: transparent;
  margin-bottom: 2rem; }
  .card img {
    margin-bottom: 0.75rem; }
  .card .card-header {
    padding: 0 0 0.75rem 0;
    background-color: transparent;
    border-bottom: none; }
    .card .card-header .card-heading {
      margin-bottom: 0.25rem;
      font-size: 14px;
      color: rgba(60, 60, 67, 0.6);
      line-height: 1.2; }
    .card .card-header .card-title {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 16px;
      color: #3C3C43;
      line-height: 1.2; }
  .card .card-body {
    padding: 0 0 1.25rem 0; }
    .card .card-body .card-text {
      color: rgba(60, 60, 67, 0.6);
      line-height: 1.5; }
  .card a {
    text-decoration: none; }
    .card a .card-img-hover-mask {
      position: relative; }
      .card a .card-img-hover-mask .card-img-hover-mask-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 0.75rem);
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0; }
    .card a:hover {
      text-decoration: none; }
      .card a:hover .card-img-hover-mask .card-img-hover-mask-content {
        opacity: 1; }

.close {
  color: rgba(60, 60, 67, 0.6);
  opacity: 1;
  text-shadow: none;
  width: 30px;
  height: 30px;
  border-radius: 10rem; }
  .close:hover {
    color: #3C3C43;
    opacity: 1 !important; }

.footer-x-twitter-link {
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #C6C6C8;
  padding: 2rem;
  margin-top: 2rem; }
  .footer-x-twitter-link .footer-x-twitter-link-icon {
    margin-right: 2rem; }
    .footer-x-twitter-link .footer-x-twitter-link-icon img {
      height: 64px;
      border-radius: 10rem; }
  .footer-x-twitter-link .footer-x-twitter-link-body {
    flex: 1; }
    .footer-x-twitter-link .footer-x-twitter-link-body .footer-x-twitter-link-name a {
      font-weight: bold;
      color: #3C3C43;
      line-height: 1.2;
      text-decoration: none; }
    .footer-x-twitter-link .footer-x-twitter-link-body .footer-x-twitter-link-username {
      margin-top: 0.5rem;
      font-size: 14px;
      color: rgba(60, 60, 67, 0.6);
      line-height: 1.2; }
    .footer-x-twitter-link .footer-x-twitter-link-body .footer-x-twitter-link-button {
      margin-top: 1rem;
      color: #FFFFFF;
      background-color: #000000 !important;
      border: none !important; }

#footer {
  padding-top: 4rem;
  padding-bottom: 2rem;
  color: rgba(60, 60, 67, 0.6);
  font-size: 14px; }
  #footer a {
    color: #3C3C43;
    text-decoration: none; }
    #footer a:hover {
      text-decoration: underline; }
  #footer .footer-brand {
    padding-bottom: 2rem; }
    #footer .footer-brand img {
      height: 34px; }
  #footer ul {
    padding-left: 0;
    margin-bottom: 0;
    display: inline-block;
    text-align: left; }
    #footer ul li {
      display: inline-block; }
      #footer ul li:not(:last-child) {
        padding-right: 1rem; }
  #footer .footer-bottom {
    margin-top: 2rem;
    line-height: 2; }
    #footer .footer-bottom a {
      color: rgba(60, 60, 67, 0.6); }
  @media (min-width: 768px) {
    #footer ul li {
      display: block; }
      #footer ul li:not(:last-child) {
        padding-right: 0; }
      #footer ul li.mt {
        margin-top: 1rem; }
    #footer .footer-bottom {
      display: flex;
      justify-content: space-between; } }

form label {
  color: #3C3C43; }

form .form-control {
  border: 1px solid #C6C6C8; }
  form .form-control:invalid {
    background-color: #FFF8E6; }

form .form-check {
  position: relative;
  margin-bottom: 0.5rem; }
  form .form-check input[type=checkbox] {
    opacity: 0; }
    form .form-check input[type=checkbox] + label {
      cursor: pointer; }
      form .form-check input[type=checkbox] + label::before {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 5px;
        left: 0;
        content: "";
        border: 1px solid #C6C6C8;
        border-radius: 3px;
        background-color: #FFFFFF; }
      form .form-check input[type=checkbox] + label::after {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 8px;
        left: 3px;
        content: "";
        background-image: url("/assets/img/common/check.svg?t=20200915");
        background-size: 8px;
        background-repeat: no-repeat;
        opacity: 0; }
    form .form-check input[type=checkbox]:checked + label::before {
      background-color: #469181;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    form .form-check input[type=checkbox]:checked + label::after {
      opacity: 1; }
  form .form-check input[type=radio] {
    opacity: 0; }
    form .form-check input[type=radio] + label {
      cursor: pointer; }
      form .form-check input[type=radio] + label::before {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 5px;
        left: -1px;
        content: "";
        border: 1px solid #C6C6C8;
        border-radius: 10rem;
        background-color: #FFFFFF; }
      form .form-check input[type=radio] + label::after {
        position: absolute;
        width: 5px;
        height: 5px;
        top: 10.5px;
        left: 4.5px;
        content: "";
        background-color: #FFFFFF;
        border-radius: 10rem;
        box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.28);
        opacity: 0; }
    form .form-check input[type=radio]:checked + label::before {
      background-color: #469181;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    form .form-check input[type=radio]:checked + label::after {
      opacity: 1; }

.frame {
  position: relative;
  padding: 2rem;
  margin-top: calc(1rem + 18px);
  margin-bottom: 1rem;
  text-align: left;
  border: 2px solid #469181;
  background-color: #FFFFFF; }
  .frame .label {
    position: absolute;
    top: -18px;
    left: 1rem;
    padding: 0.375rem 2rem;
    color: #FFFFFF;
    background-color: #469181;
    border-radius: 10rem;
    font-size: 14px; }

#header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #C6C6C8; }
  #header .container {
    position: relative; }
    #header .container .navbar-brand {
      height: 48px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 1rem; }
      #header .container .navbar-brand img {
        height: 34px; }
    #header .container .header-btn .btn {
      margin: 0.25rem 0; }
    #header .container .nav-item {
      margin: 0.25rem 0;
      font-size: 14px; }
      #header .container .nav-item .nav-link {
        color: #3C3C43;
        font-weight: bold;
        padding: 2px 0.5rem;
        border-radius: 0.25rem; }
        #header .container .nav-item .nav-link:hover {
          background-color: #F5F5F5; }
      #header .container .nav-item.dropdown .dropdown-menu {
        border: 1px solid #C6C6C8;
        font-size: 14px;
        margin-top: 0; }
        #header .container .nav-item.dropdown .dropdown-menu .dropdown-item {
          color: #3C3C43; }
          #header .container .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
            background-color: #F5F5F5; }
      #header .container .nav-item.dropdown:hover .dropdown-menu {
        display: block; }
    #header .container a.navbar-toggler {
      position: absolute;
      top: 8.5px;
      right: 12px;
      width: 1rem;
      color: rgba(60, 60, 67, 0.6); }
      #header .container a.navbar-toggler[aria-expanded="true"] .collapse-icon {
        display: inline;
        margin-left: 1.685px; }
      #header .container a.navbar-toggler[aria-expanded="true"] .expand-icon {
        display: none; }
      #header .container a.navbar-toggler[aria-expanded="false"] .collapse-icon {
        display: none; }
      #header .container a.navbar-toggler[aria-expanded="false"] .expand-icon {
        display: inline; }
    #header .container .navbar-collapse.show {
      height: calc(100vh - 64px); }
  @media (min-width: 576px) {
    #header .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    #header .container {
      max-width: 600px;
      flex: 0 0 auto;
      width: calc(100% / 12 * 10);
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 992px) {
    #header .container {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    #header .container {
      max-width: 950px; }
      #header .container .header-btn .btn {
        margin: 0 0.25rem; }
      #header .container .nav-item {
        margin: 0 0.25rem;
        font-size: 16px; }
        #header .container .nav-item.dropdown .dropdown-menu {
          font-size: 16px; } }
  @media (min-width: 1400px) {
    #header .container {
      max-width: 1100px; } }

img {
  max-width: 100%;
  height: auto; }
  img.figure-img {
    border-radius: 0; }
  img.img-fluid {
    width: 100%; }
  img.shadow {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important; }

a:hover img {
  opacity: 0.9; }

.img-hover-mask {
  position: relative; }
  .img-hover-mask .img-hover-mask-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; }
  .img-hover-mask:hover .img-hover-mask-content {
    opacity: 1; }

.modal .modal-dialog {
  margin: 0; }

.modal.fade .modal-dialog {
  transform: translate(0, 50px); }

.modal.show .modal-dialog {
  transform: none; }

.modal .modal-content {
  border-radius: 0;
  border: none; }
  .modal .modal-content .modal-body {
    padding: 0 0 1rem 0; }
    .modal .modal-content .modal-body .modal-image {
      margin-bottom: 1rem;
      margin-left: -24px;
      margin-right: -24px;
      min-width: calc(100% + 48px); }

.modal.modal-image .modal-content .modal-body {
  padding: 0; }
  .modal.modal-image .modal-content .modal-body .modal-image {
    margin-bottom: 0; }

.modal button.close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #F5F5F5;
  border: none; }

@media (min-width: 768px) {
  .modal .modal-dialog {
    margin: 1.75rem auto; }
  .modal .modal-content .modal-body {
    padding: 1rem 1px; }
    .modal .modal-content .modal-body .modal-image {
      margin: 0;
      min-width: auto; }
  .modal button.close {
    background-color: rgba(118, 118, 128, 0.12); } }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px); }
  .modal-backdrop.show {
    opacity: 1; }

.nav-bar.section {
  padding-bottom: 0; }

.nav-bar .nav-bar-content {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

@media (min-width: 768px) {
  .nav-bar .nav-bar-content {
    font-size: 16px; } }

.page-header {
  position: relative;
  min-height: 50vh;
  color: #FFFFFF; }
  .page-header a {
    color: #FFFFFF; }
    .page-header a:hover {
      color: #FFFFFF; }
  .page-header::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    z-index: -1; }
  .page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -2; }
  .page-header .nav-bar-content {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page-header .page-header-content {
    min-height: calc(50vh - 64px);
    display: flex;
    align-items: center; }

@media (min-width: 768px) {
  .page-header .nav-bar-content {
    font-size: 16px; }
  .page-header .page-header-content .description {
    width: 60%; } }

.table {
  color: #3C3C43; }
  .table th,
  .table td {
    line-height: 2;
    border-top: 1px solid #C6C6C8;
    border-bottom: 1px solid #C6C6C8; }
  .table thead th {
    border-bottom: 1px solid #C6C6C8; }
  .table.border-0 th,
  .table.border-0 td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    border: none; }

.more:not(.collapsed) {
  display: none; }

p,
ol li,
ul li {
  line-height: 2; }

ol.list-style-type-bracket {
  list-style-type: none; }
  ol.list-style-type-bracket > li {
    position: relative;
    counter-increment: counter; }
    ol.list-style-type-bracket > li::before {
      position: absolute;
      left: -25px;
      content: "(" counter(counter) ")"; }

ol.list-style-type-circle {
  list-style-type: none; }
  ol.list-style-type-circle > li {
    position: relative;
    counter-increment: counter; }
    ol.list-style-type-circle > li::before {
      position: absolute;
      left: -17px;
      content: counter(counter); }
    ol.list-style-type-circle > li::after {
      position: absolute;
      top: 8px;
      left: -22px;
      content: "";
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 10rem; }

ul.list-style-type-disc {
  list-style-type: disc; }

a {
  color: #469181; }
  a:hover {
    color: #469181; }

h1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5; }

h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5; }

h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5; }

h4 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5; }

h5 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5; }

h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5; }

.text-body {
  color: #3C3C43 !important; }

.text-primary {
  color: #469181 !important; }

.text-secondary {
  color: rgba(60, 60, 67, 0.6) !important; }

.text-accent {
  color: #FFC107; }

.text-success {
  color: #469181 !important; }

.text-danger {
  color: #BD424B !important; }

.font-family-heading {
  font-family: "Noto Sans JP", sans-serif; }

.font-size-base {
  font-size: 16px; }

.font-size-lg {
  font-size: 20px; }

.font-size-md {
  font-size: 18px; }

.font-size-sm {
  font-size: 14px; }

.line-height-base {
  line-height: 1.5; }

.line-height-lg {
  line-height: 2; }

.line-height-sm {
  line-height: 1.2; }

.title {
  position: relative; }
  .title::before {
    position: absolute;
    margin-left: -1rem;
    content: "";
    width: 0.5rem;
    height: 100%;
    background-color: #469181; }

.figure-caption {
  text-align: center;
  font-size: 14px;
  color: rgba(60, 60, 67, 0.6); }

.note {
  background-color: #F5F5F5;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: left; }

ul.annotation {
  padding-left: 0;
  list-style: none; }
  ul.annotation li {
    font-size: 14px;
    color: rgba(60, 60, 67, 0.6); }

hr {
  border-top: 1px solid #C6C6C8; }

mark {
  color: inherit;
  background: linear-gradient(transparent 50%, rgba(255, 193, 7, 0.25) 50% 85%, transparent 85%); }

@media (min-width: 768px) {
  h1 {
    font-size: 40px; }
  h2 {
    font-size: 34px; }
  h3 {
    font-size: 28px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 20px; }
  h6 {
    font-size: 16px; } }

.figure-video {
  margin-bottom: 0.5rem;
  border-radius: 0.25rem; }

.list-news .list-date {
  color: rgba(60, 60, 67, 0.6); }

.list-news .news-category {
  width: 80px; }
  .list-news .news-category.category-web {
    background-color: #1E88E5; }
  .list-news .news-category.category-blog {
    background-color: #469181; }
  .list-news .news-category.category-comment {
    background-color: #5E67AD; }
  .list-news .news-category.category-event {
    background-color: #BD424B; }
  .list-news .news-category.category-presentation {
    background-color: #FFC107; }
  .list-news .news-category.category-info {
    background-color: #F2964B; }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: #3C3C43; }

.mt-base {
  margin-top: 1rem !important; }

.mb-base {
  margin-bottom: 1rem !important; }

.ml-base {
  margin-left: 1rem !important; }

.mr-base {
  margin-right: 1rem !important; }

.mt-md {
  margin-top: 2rem !important; }

.mb-md {
  margin-bottom: 2rem !important; }

.ml-md {
  margin-left: 2rem !important; }

.mr-md {
  margin-right: 2rem !important; }

.mt-lg {
  margin-top: 100px !important; }

.mb-lg {
  margin-bottom: 100px !important; }

.ml-lg {
  margin-left: 100px !important; }

.mr-lg {
  margin-right: 100px !important; }

.bg-primary {
  background-color: #469181 !important; }

.bg-light {
  background-color: #F5F5F5 !important; }

.bg-primary-light {
  background-color: #EDF4F2 !important; }

.section {
  padding-bottom: 4rem; }
  .section.section-wide {
    padding-top: 4rem; }
  .section.offset-header {
    margin-top: 64px; }
  .section .section-header {
    margin-bottom: 2rem; }
    .section .section-header.right-item {
      display: flex;
      justify-content: space-between;
      align-items: baseline; }

.border {
  border: 1px solid #C6C6C8 !important; }

.border-radius-base {
  border-radius: 0.25rem; }

.separator:last-child {
  display: none; }

.grecaptcha-badge {
  visibility: hidden; }

.index-page .index-page-header {
  position: relative;
  min-height: 100vh;
  color: #FFFFFF; }
  .index-page .index-page-header a {
    color: #FFFFFF; }
  .index-page .index-page-header::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    z-index: -1; }
  .index-page .index-page-header.offset-header {
    min-height: calc(100vh - 64px);
    margin-top: 64px; }
  .index-page .index-page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -2; }
  .index-page .index-page-header .page-header-content {
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center; }
  .index-page .index-page-header .page-header-bottom {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%; }

@media (min-width: 768px) {
  body {
    font-size: 16px; } }
